
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Action } from 'vuex-class';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';

/**
 * Component that allows to create a new project
 */
@Component({
  components: {
    ModalWindow,
    CircleSpinner,
  },
})
export default class DuplicateDeviceWindow extends Vue {
  @Prop() deviceData!: any;
  @Prop() manageModalType!: any;
  @Action('devices/createDevice') createDevice!: (control: any) => void;
  @Action('mpc/createMCCInstance') createMCCInstance!: (control: any) => void;

  amountOfDevices = 1;
  isLoading = false;

  async dupicateDeviceObject() {
    this.isLoading = true;
    for (let index = 1; index <= this.amountOfDevices; index++) {
      const copyDeviceObject: any = {
        collection_id: this.deviceData.collection_id,
        data: this.deviceData.data,
        name: `${this.deviceData.name} (copy ${index})`,
      };
      if (this.manageModalType === 'ManageDevice' || this.manageModalType === 'ManageCharts') {
        await this.createDevice(copyDeviceObject);
      }
      if (this.manageModalType === 'ManageMLModel') {
        copyDeviceObject.project_id = this.deviceData.project_id;
        await this.createMCCInstance(copyDeviceObject);
      }
    }
    (this.$refs.ModalWindowInstance as any).closeDialog();
    this.isLoading = false;
  }
}
